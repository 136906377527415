@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans", sans-serif;
}

@layer components {
  .nav-link {
    @apply text-base font-semibold leading-6 text-white hover:text-amber-400;
  }

  .nav-link.active {
    @apply text-amber-400;
  }
}

.logo-text {
  color: #ffbd59;
  text-shadow: 0.0625rem 0.0625rem 0 #fff;
}

.magic-button {
  --border-bottom-width: 1px;
  --border-color: #ffbd59; /* Updated color */
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
    #ffbd59,
    #d8a354 78.9465%
  ); /* Updated gradient */
  height: 100%;
  opacity: 1;
  box-shadow: 0 6px 34px -6px #ffbd59,
    /* Updated shadow */ inset 0 5px 10px -8px #d0af7e,
    /* Updated shadow */ inset 0 -5px 10px -8px #d0af7e,
    /* Updated shadow */ inset -5px 0 10px -8px #d0af7e,
    /* Updated shadow */ inset 5px 0 10px -8px #d0af7e; /* Updated shadow */
}

.magic-button:hover {
  --border-bottom-width: 1px;
  --border-color: #d8a354; /* Updated color */
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
    #ffbd59,
    #f1c583 78.9465%
  ); /* Updated gradient */
  height: 100%;
  opacity: 1;
  box-shadow: 0 6px 34px -3px #ffbd59,
    /* Updated shadow */ inset 0 5px 20px -7px #d0af7e,
    /* Updated shadow */ inset 0 -5px 20px -7px #d0af7e,
    /* Updated shadow */ inset -5px 0 20px -7px #d0af7e,
    /* Updated shadow */ inset 5px 0 20px -7px #d0af7e; /* Updated shadow */
}

.bg-gradient-1 {
  background: linear-gradient(
    180deg,
    #ffbd59 0%,
    #fff 15.47%,
    #fff 33.49%,
    #fff 63.16%,
    #fff 85.69%,
    #ffbd59 100%
  );
}

.filter-hue {
  filter: hue-rotate(146deg);
}
